import Card from './components/Card';

function App() {
  return (
    <div>
      <Card />
    </div>
  )
}

export default App;
